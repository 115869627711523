import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Layout, SEO } from '@components';
import { useGlobalStateContext } from '@context';
import { DOMAIN, META_IMAGE } from '@shared/constants/constants';

import { getComponent, handleMarkdownFields, removeNullProperties } from '../helpers';
import { navigateToAnnouncement } from '@shared/navigat-helper';

const LandingPageHandler = (props) => {
  const pageData = props.data?.landersJson || props.data;
  if (props.path.includes('/plans') || props.path.includes('/special-discounts')) {
    navigateToAnnouncement();
  }
  const { metaData } = pageData;

  const { isInBuilder } = useGlobalStateContext();

  let componentsToBeRendered = [];

  if (pageData.components) {
    componentsToBeRendered = pageData.components.map((componentData, index) => {
      componentData = handleMarkdownFields(removeNullProperties(componentData));

      const Component = getComponent(componentData.type);

      if (Component) {
        return <Component {...componentData} key={componentData.type + index} />;
      }
      return null;
    });
  }

  return (
    <Layout
      navProps={{ isHidden: isInBuilder || !pageData.showNavBar }}
      footerProps={{
        isHidden: isInBuilder,
        compact: pageData.footer.compact,
        variant: pageData.footer.variant,
      }}
      wrapperProps={{ backgroundColor: 'unset' }}
      location={props.location}
    >
      {pageData.canonical?.trim() && props.location && (
        <Helmet>
          <link
            rel="canonical"
            href={`${DOMAIN.mainUrl}${pageData.canonical}`}
            data-react-helmet="true"
          />
        </Helmet>
      )}
      {!pageData.hasSEOFeaturesEnabled && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      {!isInBuilder && (
        <SEO
          title={metaData?.title}
          description={metaData?.description}
          image={META_IMAGE}
          keywords={[metaData?.keywords]}
        />
      )}

      <div>{componentsToBeRendered.map((component) => component)}</div>
    </Layout>
  );
};

export default LandingPageHandler;

export const landingPageQuery = graphql`
  query LandingPage($language: String!, $id: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "featureTabs", "plans", "checkout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    landersJson(id: { eq: $id }) {
      hasSEOFeaturesEnabled
      canonical
      metaData {
        title
        description
        keywords
      }
      showNavBar
      footer {
        compact
        variant
      }
      components {
        type
        title
        description
        image
        mobileImage
        shape
        buttons {
          title
          link
          buttonProps {
            variant
            color
            discountSubtitle
          }
        }
        showRatingsAndPlatforms
        data {
          title
          description
          image {
            src
            alt
          }
          link
          isReversed
          button {
            title
            link
            buttonProps {
              variant
              color
              size
            }
          }
          showAvailablePlatforms
        }
        items {
          title
          description
          image
          backgroundColor
        }
        backgroundColor
        button {
          title
          link
          buttonProps {
            variant
            color
            size
          }
        }
        showGuarantee
        numberOfPlans
        customQuestions {
          title
          description
        }
        fetchedQuestions
        textWidth
        imageHeight
        isReversed
        padding {
          pt {
            _
            sm
          }
          pb {
            _
            sm
          }
        }
        maxHeight
      }
    }
  }
`;
