import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import loadable from '@loadable/component';

import { DescriptionMarkdownStyleWrapper, linkTargetPlugin } from '@shared/markdown-helpers';
import { Link, Table } from '@shared/markdown-components';

const HeaderCta = loadable(() => import('./containers/header'));
const Cards = loadable(() => import('./containers/cards'));
const Features = loadable(() => import('./containers/features'));
const FeaturesTabs = loadable(() => import('./containers/features-tabs'));
const Feedback = loadable(() => import('./containers/feedback'));
const PlanComparison = loadable(() => import('./containers/plan-comparison'));
const CTAButton = loadable(() => import('./containers/button'));
const Headline = loadable(() => import('./containers/headline'));
const Guarantee = loadable(() => import('./containers/guarantee'));
const FAQ = loadable(() => import('./containers/faq'));
const PlanPicker = loadable(() => import('./containers/plan-picker'));
const FooterCta = loadable(() => import('./containers/footer-cta'));
const LastUpdatedToday = loadable(() => import('./containers/last-updated-today'));
const NewsOutletsRow = loadable(() => import('./containers/news'));
const Comparison = loadable(() => import('./containers/comparison'));
const CountryList = loadable(() => import('./containers/country-list'));
const LogoList = loadable(() => import('./containers/logo-list'));
const Accordion = loadable(() => import('./containers/accordion'));
const LogoBar = loadable(() => import('./containers/logo-bar'));

const ListItem = styled.li`
  list-style-type: disc;
`;

export const handleMarkdownFields = (object) => {
  object &&
    Object.keys(object).forEach((key) => {
      if (key === 'description') {
        if (typeof object[key] === 'string' && object[key]) {
          object[key] = (
            <DescriptionMarkdownStyleWrapper>
              <ReactMarkdown
                components={{
                  p: 'span',
                  li: ListItem,
                  a: ({ node, href, ...props }) => <Link link={href} {...props} />,
                  table: ({ node, ...props }) => <Table {...props} />,
                }}
                remarkPlugins={[remarkGfm]} // remarkGfm plugin handles advanced markdown, e.g tables
                rehypePlugins={[linkTargetPlugin, rehypeRaw]} // rehypeRaw plugin needed to support html inside markdown
              >
                {object[key]}
              </ReactMarkdown>
            </DescriptionMarkdownStyleWrapper>
          );
        }
      } else if (typeof object[key] === 'object') {
        object[key] = handleMarkdownFields(object[key]);
      }
    });
  return object;
};

export const removeNullProperties = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] && object[key].constructor === Object) {
      newObject[key] = removeNullProperties(object[key]);
    } else if (Array.isArray(object[key])) {
      newObject[key] = object[key].map((item) => removeNullProperties(item));
    } else if (object[key] !== null) {
      newObject[key] = object[key];
    }
  });

  return newObject;
};

export const getComponent = (type) => {
  switch (type) {
    case 'header':
      return HeaderCta;
    case 'features':
      return Features;
    case 'featuresTabs':
      return FeaturesTabs;
    case 'accordion':
      return Accordion;
    case 'cards':
      return Cards;
    case 'comparison':
      return Comparison;
    case 'feedback':
      return Feedback;
    case 'news':
      return NewsOutletsRow;
    case 'planPicker':
      return PlanPicker;
    case 'planComparison':
      return PlanComparison;
    case 'ctaButton':
      return CTAButton;
    case 'logoBar':
      return LogoBar;
    case 'headline':
      return Headline;
    case 'guarantee':
      return Guarantee;
    case 'faq':
      return FAQ;
    case 'footerCta':
      return FooterCta;
    case 'countryList':
      return CountryList;
    case 'lastUpdatedToday':
      return LastUpdatedToday;
    case 'logoList':
      return LogoList;
    default:
      throw new Error(`Component of type '${type}' doesn't exist`);
  }
};
